function toggleContent(btnClass, containerClass, time) {

 

    const btn = document.querySelectorAll(btnClass),
      container = document.querySelectorAll(containerClass);

    btn.forEach((item, i) => {
      let toggleBlock = gsap.to(container[i], {
        height: "auto",
        paused: true,
        autoAlpha: 1,
        duration: time,
        ease: "power1.out",
        onComplete: () => {
            ScrollTrigger.refresh();
            ScrollTrigger.update();
            console.log('update');
        },
        onReverseComplete: () => {
            ScrollTrigger.refresh();
            ScrollTrigger.update();
            console.log('update');
        }
      });

      btn[i].addEventListener("click", () => {
       
        console.log(1);
        btn[i].classList.toggle("open");
        container[i].classList.toggle("show");
        container[i].classList.contains("show") ? toggleBlock.play() : toggleBlock.reverse();
      });
    });
}
module.exports = toggleContent;
